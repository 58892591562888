import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useToasts } from 'react-toast-notifications'
import {
  Typography,
  Spacing,
  Input,
  Button,
  FlexBox,
  validateInput,
} from '@web-applications/daffodil-component-library'

//image
import Logo from './../assets/images/Daffodil-WIFI-Logo-Dark.png'

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(email: $email)
  }
`

export default function ForgottenPassword({ history }) {
  const { addToast } = useToasts()
  const [resetSent, setResetSent] = useState(false)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setResetSent(true)
      },
    }
  )

  //redirect user to home page if they try to access this page whilst already logged in

  if (Cookies.get('dwifi')) history.push('/')

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      {!resetSent ? (
        <Fragment>
          <Typography variant="h5" center>
            Password Recovery
          </Typography>
          <Spacing multiplier={6} />
          <Form
            onSubmit={({ email }) => {
              requestPasswordReset({
                variables: {
                  email: email,
                },
              })
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Container>
                  <Row justifyContent="center">
                    <Col xl="4">
                      <Field
                        component={Input}
                        name="email"
                        required
                        fullwidth
                        label="Email Address"
                        type="email"
                        autoComplete
                        validate={(value) =>
                          validateInput(value, 'email', true)
                        }
                      />
                      <Spacing multiplier={1} />
                    </Col>
                  </Row>
                  <Row justifyContent="center">
                    <Col xl="4">
                      <Button
                        fullwidth
                        loading={loading}
                        disabled={pristine || invalid}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                      <Spacing multiplier={3} />
                      <Typography
                        variant="label"
                        component={Link}
                        to="login"
                        center
                      >
                        Back
                      </Typography>
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          />
        </Fragment>
      ) : (
        <Container>
          <Row justifyContent="center">
            <Col xl="6">
              <Typography variant="h5" center>
                Thank You!
              </Typography>
              <Spacing multiplier={6} />
              <Typography variant="bodySmall" colour="#626C79" center>
                If you have an account, we'll email you a reset link.
              </Typography>
              <Spacing multiplier={2} />
              <Typography variant="bodySmall" colour="#626C79" center>
                If no email is received within five minutes, check the email
                address you submitted is correct and try again.
              </Typography>
              <Spacing multiplier={5} />
              <FlexBox justifyContent="center">
                <Button component={Link} to="login">
                  Back To Login
                </Button>
              </FlexBox>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}
