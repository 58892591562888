import React, { Fragment } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Spacing,
  Typography,
} from '@web-applications/daffodil-component-library'

export default function Footer() {
  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col xl="4">
            <Typography variant="bodySmall" center>
              &copy; {new Date().getFullYear()} Daffodil Group. All Rights
              Reserved.
            </Typography>
            <Typography
              variant="bodySmall"
              component="a"
              href="http://daffodil-wifi.co.uk/terms-and-conditions/"
              center
              colour="#1344B4"
            >
              Terms & Conditions
            </Typography>
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={9} />
    </Fragment>
  )
}
