import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useToasts } from 'react-toast-notifications'

import {
  Typography,
  Spacing,
  Input,
  Button,
  Loading,
  ErrorMessage,
  FlexBox,
  validateInput,
} from '@web-applications/daffodil-component-library'

//image
import Logo from './../assets/images/Daffodil-WIFI-Logo-Dark.png'

const CHECK_PASSWORD_RESET_TOKEN = gql`
  query checkPasswordResetToken($resetToken: String!) {
    checkPasswordResetToken(resetToken: $resetToken) {
      user {
        id
      }
      loginUrl
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($userId: ID!, $password: String!) {
    resetPassword(userId: $userId, password: $password)
  }
`

export default function ResetPassword({
  match: {
    params: { resetToken },
  },
  history,
}) {
  const { addToast } = useToasts()
  const [passwordReset, setPasswordReset] = useState(false)

  const { loading, error, data } = useQuery(CHECK_PASSWORD_RESET_TOKEN, {
    variables: {
      resetToken: resetToken,
    },
  })

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setPasswordReset(true)
      },
    }
  )

  //redirect user to home page if they try to access this page whilst already logged in

  if (Cookies.get('dwifi')) history.push('/')

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const {
    user: { id: userId },
    loginUrl,
  } = data.checkPasswordResetToken

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      {!passwordReset ? (
        <Fragment>
          <Typography variant="h5" center>
            Reset Password
          </Typography>
          <Spacing multiplier={6} />
          <Form
            onSubmit={({ confirmPassword }) => {
              resetPassword({
                variables: { userId: userId, password: confirmPassword },
              })
            }}
            validate={({ newPassword, confirmPassword }) => {
              let errors = {}

              if (confirmPassword && confirmPassword !== newPassword) {
                errors.confirmPassword = 'Passwords Must Match'
              }

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Container>
                  <Row justifyContent="center">
                    <Col xl="4">
                      <Field
                        component={Input}
                        name="newPassword"
                        required
                        fullwidth
                        label="New Password"
                        type="password"
                        autoComplete
                        validate={(value) =>
                          validateInput(value, 'password', true)
                        }
                      />
                      <Field
                        component={Input}
                        name="confirmPassword"
                        required
                        fullwidth
                        label="Confirm Password"
                        type="password"
                        autoComplete
                        validate={(value) =>
                          validateInput(value, 'password', true)
                        }
                      />
                    </Col>
                    <Spacing multiplier={1} />
                  </Row>
                  <Row justifyContent="center">
                    <Col xl="4">
                      <Button
                        fullwidth
                        loading={resetPasswordLoading}
                        disabled={pristine || invalid}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          />
        </Fragment>
      ) : (
        <Container>
          <Row justifyContent="center">
            <Col xl="6">
              <Typography variant="h5" center>
                Password Updated
              </Typography>
              <Spacing multiplier={6} />
              <Typography variant="bodySmall" colour="#626C79" center>
                Your password has been changed. Click the button below to return
                to the login page
              </Typography>
              <Spacing multiplier={5} />
              <FlexBox justifyContent="center">
                {loginUrl ? (
                  <Button component="a" href={loginUrl}>
                    Back To Login
                  </Button>
                ) : (
                  <Button component={Link} to="/login">
                    Back To Login
                  </Button>
                )}
              </FlexBox>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}
