import React, { useContext, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Form, Field } from "react-final-form";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { validate as postcodeValidate } from "postal-codes-js";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  validateInput,
  Input,
  DropdownLookup,
  validatePhoneNumber,
  getCountryNames,
  getCounties,
  getAddressFields,
  Spacing,
  CardHeader,
} from "@web-applications/daffodil-component-library";
import { useToasts } from "react-toast-notifications";

import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import { GET_BUSINESS_DETAILS } from "../Business/BusinessDetails";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../util/PageWrapper";

const CREATE_SITE = gql`
  mutation createSite(
    $name: String!
    $business: ID!
    $line1: String!
    $line2: String
    $city: String!
    $county: String!
    $country: String!
    $postcode: String!
    $phone: String!
  ) {
    createSite(
      name: $name
      business: $business
      line1: $line1
      line2: $line2
      city: $city
      county: $county
      country: $country
      postcode: $postcode
      phone: $phone
    ) {
      id
      name
    }
  }
`;

export default function NewSite({
  match: {
    params: { businessId },
  },
}) {
  const user = useContext(UserContext);

  const history = useHistory();

  const { addToast } = useToasts();
  const [isManualEntry, setIsManualEntry] = useState(true);

  const automaticAddressCountries = [];

  const [createSite, { createSiteLoading }] = useMutation(CREATE_SITE, {
    onCompleted: ({ createSite: { name } }) => {
      addToast(`${name} has been successfully created.`, {
        appearance: "success",
      });
      history.push("../");
    },
    refetchQueries: [
      {
        query: GET_BUSINESS_DETAILS,
        variables: { businessId },
      },
    ],
    onError: () => {
      addToast("Something went wrong, please try again.", {
        appearance: "error",
      });
    },
  });

  const countryNames = getCountryNames().map(({ code, name }) => ({
    value: code,
    label: name,
    visible: true,
  }));

  const ukCounties = getCounties("GB").map((countyName) => ({
    value: countyName,
    label: countyName,
    visible: true,
  }));

  if (user.roleId < 5) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h4">New Site Details</Typography>

          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form
            onSubmit={({
              name,
              line1,
              line2,
              city,
              county,
              country,
              postcode,
              phone,
            }) => {
              createSite({
                variables: {
                  name,
                  business: businessId,
                  line1,
                  line2,
                  city,
                  county,
                  country,
                  postcode,
                  phone,
                },
              });
            }}
            initialValues={{}}
            validate={({ phone, postcode, country }) => {
              const errors = {};

              if (phone && country)
                errors.mobile = validatePhoneNumber(phone, country);

              if (
                postcode &&
                country &&
                postcodeValidate(country, postcode) !== true
              )
                errors.postcode = "Invalid Postcode";

              return errors;
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              values,
              values: { country },
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader lite>
                    <Typography variant="h4">Contact Details</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="name"
                      required
                      fullwidth
                      label="Site Name"
                      validate={(value) => validateInput(value, "text", true)}
                    />
                    <Spacing multiplier={1} />

                    <Field
                      component={Input}
                      name="phone"
                      type="number"
                      label="Phone"
                      required
                      fullwidth
                      validate={(value) => validateInput(value, "number", true)}
                    />
                    <Typography variant="h5">Address</Typography>
                    <Spacing multiplier={1} />
                    <Field
                      component={DropdownLookup}
                      name="country"
                      label="Country"
                      options={countryNames}
                      fullwidth
                      required
                      validate={(value) =>
                        validateInput(value, "dropdownLookup", true)
                      }
                    />
                    {getAddressFields(country).map(
                      ({
                        fieldType,
                        name,
                        label,
                        fullWidth,
                        required: fieldRequired,
                      }) => {
                        switch (fieldType) {
                          case "dropdownLookup":
                            if (!isManualEntry) return null;

                            if (country === "GB")
                              return (
                                <Field
                                  key={name}
                                  component={DropdownLookup}
                                  name={name}
                                  label={label}
                                  fullwidth={fullWidth}
                                  options={ukCounties}
                                  required={fieldRequired}
                                  validate={(value) =>
                                    validateInput(
                                      value,
                                      fieldType,
                                      fieldRequired
                                    )
                                  }
                                />
                              );
                            return null;

                          case "postcode":
                            return (
                              <Field
                                component={Input}
                                type="postcode"
                                key={name}
                                name={name}
                                label={label}
                                fullwidth={fullWidth}
                                required={fieldRequired}
                                validate={(value) => {
                                  if (
                                    value &&
                                    postcodeValidate(country, value) !== true
                                  )
                                    return "Invalid Postcode";
                                }}
                              />
                            );

                          default:
                            if (!isManualEntry) return null;
                            return (
                              <Field
                                component={Input}
                                key={name}
                                name={name}
                                label={label}
                                type={fieldType}
                                fullwidth={fullWidth}
                                required={fieldRequired}
                                validate={(value) =>
                                  validateInput(value, fieldType, fieldRequired)
                                }
                              />
                            );
                        }
                      }
                    )}
                    {automaticAddressCountries.includes(country) && (
                      <Typography
                        variant="bodySmall"
                        onClick={() => {
                          setIsManualEntry(!isManualEntry);
                        }}
                      >
                        {isManualEntry
                          ? "Find address by postcode "
                          : "Enter address manually"}
                      </Typography>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={createSiteLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}
