import React, { useContext } from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import { UserContext } from "../../util/PageWrapper";

import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  ErrorMessage,
  Loading,
  Typography,
  Card,
  CardBody,
  Button,
  CardHeader,
  Spacing,
  getCountryName,
  FlexBox,
  Dropdown,
  MenuItem,
} from "@web-applications/daffodil-component-library";
import Icon from "@mdi/react";
import { mdiDotsVertical, mdiCog } from "@mdi/js";
import { useToasts } from "react-toast-notifications/dist/ToastProvider";
import { format } from "date-fns";

const GET_SITE = gql`
  query getSiteInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      id
      name
      address {
        line1
        line2
        city
        county
        country
        postcode
      }
      cpUrl
      redirectUrl
      logo
    }
  }
`;

const GET_SITE_FOR_DOWNLOAD = gql`
  query getSiteInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      agreedUsers {
        user {
          firstName
          lastName
          email
          dateOfBirth
          gender
          createdDate
          country
          postcode
          mobile
          wifiMarketingMethods
        }
      }
    }
  }
`;

export default function Site({
  match: {
    params: { siteReference },
  },
}) {
  const user = useContext(UserContext);
  const { addToast } = useToasts();

  const { loading, error, data } = useQuery(GET_SITE, {
    variables: { siteReference },
  });

  const [downloadCsv/*, {  data: _data }*/] = useLazyQuery(GET_SITE_FOR_DOWNLOAD, {
    variables: { siteReference },
    fetchPolicy: "network-only",

    onError: () => {
      addToast("Something went wrong, please try again.", {
        appearance: "error",
      });
    },
    onCompleted: (downloadData) => {
      let rows = [['First Name','Last Name','Email','Date Of Birth','Gender','Date Registered','Country','Postcode','Mobile','Marketing Methods Agreed To']];

      if (downloadData?.getSite?.agreedUsers)
        downloadData.getSite.agreedUsers.forEach((user) => {
          rows.push([
            user.user.firstName,
            user.user.lastName,
            user.user.email,
            format(new Date(user.user.dateOfBirth), "yyyy-dd-MM"),
            user.user.gender,
            format(new Date(user.user.createdDate), "yyyy-dd-MM"),
            user.user.country,
            user.user.postcode,
            user.user.mobile,
            user.user.wifiMarketingMethods,
          ]);
        });

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${siteReference}.csv`);
      document.body.appendChild(link);

      link.click();
    },
  });

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  const { name, address, cpUrl, redirectUrl, logo } = data.getSite;

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h2">{name}</Typography>{" "}
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <CardHeader lite>
              <FlexBox justifyContent="space-between">
                <Typography variant="h4">Site Details</Typography>
                <div>
                  <Dropdown anchor="center">
                    <Button
                      colour="#626C79"
                      variant="ghost"
                      icon={<Icon path={mdiDotsVertical} size="24px" />}
                      dropdownTrigger
                    />
                    <MenuItem
                      component={Link}
                      to={`/edit-site/${siteReference}`}
                    >
                      <Typography variant="bodySmall" colour="#1C1C26">
                        Edit Site Details
                      </Typography>
                    </MenuItem>
                    {user.roleId >= 5 && (
                      <MenuItem onClick={() => downloadCsv()}>
                        <Typography variant="bodySmall">
                          Download CSV
                        </Typography>
                      </MenuItem>
                    )}
                  </Dropdown>
                </div>
              </FlexBox>
            </CardHeader>
            <CardBody>
              <Typography variant="label">Name</Typography>
              <Typography variant="bodySmall">{name}</Typography>
              <Spacing multiplier={2} />
              <Typography variant="label">Address</Typography>
              <Typography variant="bodySmall">
                {`${address.line1}, ${
                  !!address.line2 ? address.line2 + ", " : ""
                }${address.city}, ${address.county}, ${getCountryName(
                  address.country
                )}, ${address.postcode}`}
              </Typography>
            </CardBody>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <CardHeader>
              <FlexBox justifyContent="space-between">
                <Typography variant="h4">Captive Portal</Typography>
                <Button
                  component={Link}
                  to={`/edit-captive-portal/${siteReference}`}
                  icon={<Icon path={mdiCog} size="24px" />}
                >
                  Manage Captive Portal
                </Button>
              </FlexBox>
            </CardHeader>
            <CardBody>
              <Typography variant="label">Welcome Image</Typography>
              <img src={logo} alt="Site Logo" height="150px" width="100%" />
              <Spacing multiplier={2} />
              <Typography variant="label">Redirect Url</Typography>
              <Typography variant="bodySmall">{redirectUrl}</Typography>
              <Spacing multiplier={2} />
              <Typography variant="label">Captive Portal Url</Typography>
              <Typography variant="bodySmall">{cpUrl}</Typography>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
