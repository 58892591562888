import React, { Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../util/PageWrapper";

import { Form } from "react-final-form";
import { useToasts } from "react-toast-notifications";
import validator from "validator";

import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  CardContent,
  IconButton,
  CardHeader,
  CardActions,
} from "@material-ui/core";

import {
  Loading,
  Spacing,
  Card,
  CardBody,
  Button,
  FlexBox,
  Typography,
} from "@web-applications/daffodil-component-library";

import UserEdit from "./UserEdit";
import ResetPassword from "./ResetPassword";
import { closeModal, openModal } from "../../../components/reusable/Popup";

import { Save, Clear } from "@material-ui/icons";

export const ARCHIVE_USER = gql`
  mutation ($userId: String!) {
    archiveUser(userId: $userId) {
      id
      fullName
    }
  }
`;

export const UNARCHIVE_USER = gql`
  mutation ($userId: String!) {
    unArchiveUser(userId: $userId) {
      id
      fullName
    }
  }
`;

const UPDATE_USER_PERSONAL_DETAILS = gql`
  mutation updateUserPersonalDetailsByAdmin(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $roleId: Int!
  ) {
    updateUserPersonalDetailsByAdmin(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      roleId: $roleId
    ) {
      id
      fullName
    }
  }
`;

const GET_USER = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      id
      firstName
      lastName
      fullName
      email
      roleId
      archived
    }
  }
`;

export default function UserView() {
  const userFromContext = useContext(UserContext);
  const { addToast } = useToasts();

  const params = useParams();
  const { userId } = params;

  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      userId: userId,
    },
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_PERSONAL_DETAILS,
    {
      onCompleted: ({ updateUserPersonalDetailsByAdmin }) => {
        addToast(
          updateUserPersonalDetailsByAdmin.fullName +
            " has been successfully updated",
          {
            appearance: "success",
          }
        );
      },
      onError: () => {
        addToast("Failed to update user, please try again", {
          appearance: "error",
        });
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  );
  const [unArchiveUser, { loading: unArchiveUserLoading }] = useMutation(
    UNARCHIVE_USER,
    {
      onCompleted: ({ unArchiveUser }) => {
        addToast(unArchiveUser.fullName + " has been successfully updated", {
          appearance: "success",
        });
        closeModal();
      },
      onError: () => {
        addToast("Failed to update user, please try again", {
          appearance: "error",
        });
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  );
  const [archiveUser, { loading: archiveUserLoading }] = useMutation(
    ARCHIVE_USER,
    {
      onCompleted: ({ archiveUser }) => {
        addToast(archiveUser.fullName + " has been successfully updated", {
          appearance: "success",
        });
        closeModal();
      },
      onError: () => {
        addToast("Failed to update user, please try again", {
          appearance: "error",
        });
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  );

  if (error || userFromContext.roleId < 5) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const { getUser } = data;
  const user = getUser;

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h4">
            {`Edit User - ${user.fullName}`}
          </Typography>{" "}
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox justifyContent="flex-end">
            {user.archived ? (
              <Button
                variant="contained"
                style={{
                  marginLeft: "24px",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={() => {
                  unArchiveUser({
                    variables: {
                      userId: userId,
                    },
                  });
                }}
              >
                Unarchive User
              </Button>
            ) : (
              <Fragment>
                <ResetPassword email={user.email} />

                <Button
                  variant="contained"
                  style={{
                    marginLeft: "24px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    openModal({
                      content: (
                        <Card>
                          <CardHeader
                            action={
                              <IconButton
                                aria-label="Close Pop-up"
                                onClick={closeModal}
                              >
                                <Clear />
                              </IconButton>
                            }
                            title="Are You Sure?"
                          />
                          <CardContent>
                            <Typography variant="body1" gutterBottom>
                              Archiving a user can be undone.
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={closeModal}
                            >
                              Cancel
                            </Button>

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                archiveUser({
                                  variables: {
                                    userId: userId,
                                  },
                                });
                              }}
                            >
                              Continue
                            </Button>
                          </CardActions>
                        </Card>
                      ),
                    });
                  }}
                >
                  Archive User
                </Button>
              </Fragment>
            )}
          </FlexBox>
        </Col>
      </Row>

      <Spacing multiplier={3} />

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Form
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  roleId: user.roleId,
                }}
                onSubmit={(values) => {
                  updateUser({
                    variables: {
                      userId: userId,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      roleId: values.roleId,
                    },
                  });
                }}
                validate={(values) => {
                  const errors = {};

                  const requiredFields = ["firstName", "lastName", "email"];

                  requiredFields.forEach((requiredField) => {
                    if (!values[requiredField]) {
                      errors[requiredField] = "Required";
                    }
                  });

                  if (
                    values.email &&
                    !validator.isEmail(values.email) &&
                    !values.email.includes(".con") &&
                    !values.email.includes(".coma")
                  ) {
                    errors.email = "Invalid Email";
                  }

                  return errors;
                }}
                render={({
                  handleSubmit,
                  pristine,
                  invalid,
                  submitting,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <UserEdit archived={user.archived} />

                    {!user.archived && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={pristine || invalid || submitting}
                        loading={
                          updateUserLoading ||
                          unArchiveUserLoading ||
                          archiveUserLoading
                        }
                        type="submit"
                      >
                        <Save style={{ marginRight: "10px" }} />
                        Save
                      </Button>
                    )}
                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  </form>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
