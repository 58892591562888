import React, { useContext } from "react";
import { UserContext } from "../../util/PageWrapper";

import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import {
  Card,
  Spacing,
  CardBody,
  Typography,
} from "@web-applications/daffodil-component-library";

export default function AdminSettings() {
  const user = useContext(UserContext);

  if (user.roleId < 5) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={4} />
          <Typography variant="h1">Admin Settings</Typography>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Link to={`/admin-settings/users`}>
            <Card>
              <CardBody>
                <Spacing multiplier={2} />
                <Typography variant="h5" center>
                  Users
                </Typography>
                <Spacing multiplier={2} />
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
