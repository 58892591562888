import React, { createContext } from 'react'
import styled from 'styled-components'
import { ModalProvider } from 'react-modal-hook'

import { Spacing } from '@web-applications/daffodil-component-library'
import Header from './../components/Header'
import Footer from './../components/Footer'

//create site wrapped component

export const UserContext = createContext()

const SiteBody = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.chalk};
`

const SiteContent = styled.div`
  flex: 1;
`

export default function PageWrapper({ children, user }) {
  return (
    <UserContext.Provider value={user ? { ...user } : user}>
      <ModalProvider>
        <SiteBody>
          <Header />
          <SiteContent>
            {children}
            <Spacing multiplier={6} />
          </SiteContent>
          <Footer />
        </SiteBody>
      </ModalProvider>
    </UserContext.Provider>
  )
}
