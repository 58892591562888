import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import { Loading } from '@web-applications/daffodil-component-library'

import PageWrapper from './PageWrapper'

const AUTHENTICATE_USER = gql`
  query getUser {
    getUser {
      id
      email
      fullName
      roleId
    }
  }
`

export default function AuthenticatedRoute({
  component: Component,
  title,
  ...props
}) {
  const { loading, error, data } = useQuery(AUTHENTICATE_USER, {
    fetchPolicy: 'network-only',
  })

  if (error || !Cookies.get('dwifi')) {
    return <Redirect to="/login" />
  }

  //user exists, add them to sentry

  if (data) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: data.getUser.id,
        username: data.getUser.fullName,
        email: data.getUser.email,
      })
    })
  }

  return (
    <Route
      {...props}
      render={(innerProps) => (
        <PageWrapper user={data?.getUser}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {loading ? <Loading /> : <Component {...innerProps} />}
        </PageWrapper>
      )}
    />
  )
}
