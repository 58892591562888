import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import { darken } from "polished";
import { mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Cookies from "js-cookie";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Dropdown,
  FlexBox,
  MenuItem,
  Typography,
} from "@web-applications/daffodil-component-library";

import { UserContext } from "./../util/PageWrapper";

import logo from "./../assets/images/Daffodil-WIFI-Logo.png";
import SettingsIcon from "@mui/icons-material/Settings";

//header height is calculated from the baseline

const StyledHeader = styled.div`
  min-height: ${({ theme }) => `${theme.baseline * 9}px`};
  background-color: #2a2a3e;
  margin-bottom: ${({ theme, path }) => {
    //if on login page, return higher pixel amount, else its multiple of 3
    if (
      path.includes("/login") ||
      path.includes("/forgotten-password") ||
      path.includes("/reset-password")
    )
      return `${theme.baseline * 8}px`;

    return `${theme.baseline * 3}px`;
  }};
  padding: 0 24px;
  display: flex;
  align-items: stretch;
  .logo {
    height: 32px;
    width: 200px;
  }
`;

const VerticalDivider = styled.div`
  height: ${({ theme }) => `${theme.baseline * 5}px`};
  width: 1px;
  background-color: ${({ theme }) => `${theme.colours.chalk}`};
  opacity: 10%;
  margin: 0 8px 0 16px;
  align-self: center;
`;

const NavigationLink = styled(Link)`
  padding: 0 16px;
  display: flex;
  align-items: center;
  transition: all ease-in-out 150ms;
  text-decoration: none;
  white-space: nowrap;
  height: ${({ as }) => (as === "div" ? "100%" : "")};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => darken(0.08, theme.colours.brand)};
  }
`;

const UserAccount = styled.div`
  text-decoration: none;
  padding: 0 8px 0 16px;
  display: flex;
  align-items: center;
  transition: all ease-in-out 150ms;
  height: 100%;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => darken(0.08, theme.colours.brand)};
    cursor: pointer;
  }
  svg {
    margin-left: 4px;
  }
`;
//need to use this for positioning of the user account with a dropdown
const UserAccountWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const LOGOUT = gql`
  mutation logoutUser {
    logoutUser
  }
`;

export default function Header() {
  const user = useContext(UserContext);

  const history = useHistory();
  const { pathname } = useLocation();

  const [logoutUser, { loading, client }] = useMutation(LOGOUT, {
    onCompleted: () => {
      Cookies.remove("dwifi");
      client.resetStore();
      history.push("/login");
    },
  });

  return (
    <StyledHeader path={pathname}>
      {user ? (
        <Fragment>
          <FlexBox alignItems="center">
            <Link to="/">
              <img
                src={logo}
                alt="Daffodil Dashboard Small Logo"
                className="logo"
              />
            </Link>
          </FlexBox>
          <VerticalDivider />
          <NavigationLink to="/">
            <Typography variant="navigation" colour="#fff">
              Dashboard
            </Typography>
          </NavigationLink>

          <UserAccountWrapper>
            {user.roleId >= 5 && (
              <NavigationLink to="/admin-settings">
                <SettingsIcon style={{ fill: "white" }} />
                <Typography variant="navigation" colour="#fff">
                  Admin Settings
                </Typography>
              </NavigationLink>
            )}

            <Dropdown anchor="right">
              <UserAccount dropdownTrigger>
                <Typography variant="navigation" colour="#fff">
                  {user.fullName}
                </Typography>
                <Icon
                  path={mdiMenuDown}
                  title="User Profile"
                  size="24px"
                  color="white"
                />
              </UserAccount>
              <MenuItem component={Link} to="/edit-user">
                <Typography variant="bodySmall">Account Details</Typography>
              </MenuItem>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  logoutUser();
                }}
              >
                <Typography variant="bodySmall">Logout</Typography>
              </MenuItem>
            </Dropdown>
          </UserAccountWrapper>
        </Fragment>
      ) : (
        <FlexBox alignItems="center">
          <img src={logo} alt="Daffodil Wifi Logo" className="logo" />
        </FlexBox>
      )}
    </StyledHeader>
  );
}
