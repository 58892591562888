import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import {
  ErrorMessage,
  Loading,
  Card,
  Spacing,
  CardBody,
  Typography,
  Button,
  FlexBox,
} from "@web-applications/daffodil-component-library";
import { Add } from "@material-ui/icons";

export const GET_BUSINESSES = gql`
  query getBusinesses {
    getBusinesses {
      id
      name
    }
  }
`;
export default function AdminDashboard() {
  const { loading, error, data } = useQuery(GET_BUSINESSES);

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={4} />
          <FlexBox justifyContent="space-between">
            <Typography variant="h1">Businesses</Typography>
            <Button
              component={Link}
              to="businesses/add"
              icon={<Add style={{ marginRight: "10px" }} />}
            >
              New Business
            </Button>
          </FlexBox>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        {data.getBusinesses.map(({ id, name }) => (
          <Col md={4} key={id}>
            <Link to={`/business/${id}`}>
              <Card>
                <CardBody>
                  <Spacing multiplier={2} />
                  <Typography variant="h5" center>
                    {name}
                  </Typography>
                  <Spacing multiplier={2} />
                </CardBody>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
