import React, { useContext } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../util/PageWrapper";

import { useToasts } from "react-toast-notifications";
import { Form, Field } from "react-final-form";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  Spacing,
  Input,
  Button,
  validateInput,
  Typography,
  Card,
  CardBody,
} from "@web-applications/daffodil-component-library";

import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import { GET_BUSINESSES } from "../Dashboards/AdminDashboard";

const CREATE_BUSINESS = gql`
  mutation createBusiness($name: String!) {
    createBusiness(name: $name) {
      id
      name
    }
  }
`;

export default function Register() {
  const user = useContext(UserContext);
  const { addToast } = useToasts();
  const history = useHistory();

  const [createBusiness, { loading }] = useMutation(CREATE_BUSINESS, {
    onCompleted: ({ createBusiness }) => {
      addToast(
        `Business ${createBusiness.name} has been successfully created`,
        {
          appearance: "success",
        }
      );
      history.push("../");
    },
    refetchQueries: [
      {
        query: GET_BUSINESSES,
      },
    ],
  });

  if (user.roleId < 5) {
    return null;
  }

  return (
    <Form
      initialValues={{}}
      onSubmit={({ name }) => {
        createBusiness({
          variables: {
            name,
          },
        }).catch((error) => {
          addToast("Failed to create business, please try again", {
            appearance: "error",
          });
        });
      }}
      validate={({ confirmPassword, password }) => {
        const errors = {};

        if (confirmPassword && confirmPassword !== password) {
          errors.confirmPassword = "Passwords Must Match";
        }

        return errors;
      }}
      render={({
        values,
        handleSubmit,
        pristine,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Container>
            <Row>
              <Col>
                <Spacing multiplier={2} />
                <Typography variant="h4">New Business</Typography>
                <Spacing multiplier={5} />
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row>
                  <Col md="5">
                    <Field
                      component={Input}
                      name="name"
                      required
                      fullwidth
                      label="Name"
                      validate={(value) => validateInput(value, "text", true)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Button
                      disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                      type="Submit"
                      loading={loading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
  );
}
