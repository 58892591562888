import React, { useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { validate as postcodeValidate } from 'postal-codes-js'
import {
  ErrorMessage,
  Loading,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  validateInput,
  Input,
  DropdownLookup,
  validatePhoneNumber,
  getCountryNames,
  getCounties,
  getAddressFields,
  Select,
  Spacing,
  CardHeader,
} from '@web-applications/daffodil-component-library'
import { useToasts } from 'react-toast-notifications'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import { format, parseISO } from 'date-fns'
import Icon from '@mdi/react'
import { mdiContentSave } from '@mdi/js'

const GET_SITE = gql`
  query getSiteInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      id
      name
      address {
        line1
        line2
        city
        county
        country
        postcode
      }
      phone
      updatedDate
      hardware
    }
  }
`

const UPDATE_SITE = gql`
  mutation updateSiteContactDetails(
    $id: ID!
    $line1: String!
    $line2: String
    $city: String!
    $county: String!
    $country: String!
    $postcode: String!
    $phone: String!
  ) {
    updateSiteContactDetails(
      id: $id
      line1: $line1
      line2: $line2
      city: $city
      county: $county
      country: $country
      postcode: $postcode
      phone: $phone
    ) {
      id
      name
      address {
        line1
        line2
        city
        county
        country
        postcode
      }
      phone
      updatedDate
    }
  }
`

const UPDATE_SITE_DETAILS = gql`
  mutation updateSiteDetails($id: ID!, $name: String!, $hardware: String!) {
    updateSiteDetails(id: $id, name: $name, hardware: $hardware) {
      id
      name
      updatedDate
      hardware
    }
  }
`

export default function EditSite({
  match: {
    params: { siteReference },
  },
}) {
  const { addToast } = useToasts()
  const [isManualEntry, setIsManualEntry] = useState(true)

  const automaticAddressCountries = []

  const [
    updateSiteContactDetails,
    { updateSiteContactDetailsLoading },
  ] = useMutation(UPDATE_SITE, {
    onCompleted: ({ updateSiteContactDetails: { name } }) => {
      addToast(`${name} has been updated.`, {
        appearance: 'success',
      })
    },
    onError: () => {
      addToast('Something went wrong, please try again.', {
        appearance: 'error',
      })
    },
  })

  const [updateSiteDetails, { updateSiteDetailsLoading }] = useMutation(
    UPDATE_SITE_DETAILS,
    {
      onCompleted: ({ updateSiteDetails: { name } }) => {
        addToast(`${name} has been updated.`, {
          appearance: 'success',
        })
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
    }
  )

  const { loading, error, data } = useQuery(GET_SITE, {
    variables: { siteReference },
  })

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const {
    name: siteName,
    address,
    phone,
    updatedDate,
    hardware,
    id: siteId,
  } = data.getSite

  //calculate user mobile

  const parsedNumber = parsePhoneNumberFromString(phone)

  const sitePhone = parsedNumber
    .format('IDD', {
      fromCountry: address.country,
      humanReadable: true,
    })
    .replace(' ', '')

  const countryNames = getCountryNames().map(({ code, name }) => ({
    value: code,
    label: name,
    visible: true,
  }))

  const ukCounties = getCounties('GB').map((countyName) => ({
    value: countyName,
    label: countyName,
    visible: true,
  }))

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h4">Site Details</Typography>
          <Typography variant="bodySmall" colour="#1C1C26">
            {'Last Updated: ' +
              format(parseISO(updatedDate), "do MMMM y 'at' HH:mm")}
          </Typography>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form
            onSubmit={({ name, hardware }) => {
              updateSiteDetails({
                variables: {
                  id: siteId,
                  name,
                  hardware,
                },
              })
            }}
            initialValues={{
              name: siteName,
              hardware,
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader lite>
                    <Typography variant="h4">Details</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="name"
                      required
                      fullwidth
                      label="Site Name"
                      validate={(value) => validateInput(value, 'text', true)}
                    />

                    <Field
                      component={Select}
                      name="hardware"
                      label="Hardware"
                      required
                      fullwidth
                      options={[
                        {
                          value: 'Ubiquiti',
                          label: 'Ubiquiti',
                          visible: true,
                        },
                      ]}
                      format={(value) => {
                        const selectedOption = [
                          {
                            value: 'Ubiquiti',
                            label: 'Ubiquiti',
                            visible: true,
                          },
                        ].find((option) => option.value === value)

                        return selectedOption?.label
                      }}
                    />
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={updateSiteDetailsLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
        <Col md={6}>
          <Form
            onSubmit={({
              line1,
              line2,
              city,
              county,
              country,
              postcode,
              phone,
            }) => {
              updateSiteContactDetails({
                variables: {
                  id: siteId,
                  line1,
                  line2,
                  city,
                  county,
                  country,
                  postcode,
                  phone,
                },
              })
            }}
            initialValues={{
              line1: address.line1,
              line2: address.line2,
              city: address.city,
              county: address.county,
              country: address.country,
              postcode: address.postcode,
              phone: sitePhone,
            }}
            validate={({ phone, postcode, country }) => {
              const errors = {}

              if (phone && country)
                errors.mobile = validatePhoneNumber(phone, country)

              if (
                postcode &&
                country &&
                postcodeValidate(country, postcode) !== true
              )
                errors.postcode = 'Invalid Postcode'

              return errors
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              values: { country },
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader lite>
                    <Typography variant="h4">Contact Details</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="phone"
                      type="number"
                      label="Phone"
                      required
                      fullwidth
                      validate={(value) => validateInput(value, 'number', true)}
                    />
                    <Typography variant="h5">Address</Typography>
                    <Spacing multiplier={1} />
                    <Field
                      component={DropdownLookup}
                      name="country"
                      label="Country"
                      options={countryNames}
                      fullwidth
                      required
                      validate={(value) =>
                        validateInput(value, 'dropdownLookup', true)
                      }
                    />
                    {getAddressFields(country).map(
                      ({
                        fieldType,
                        name,
                        label,
                        fullWidth,
                        required: fieldRequired,
                      }) => {
                        switch (fieldType) {
                          case 'dropdownLookup':
                            if (!isManualEntry) return null

                            if (country === 'GB')
                              return (
                                <Field
                                  key={name}
                                  component={DropdownLookup}
                                  name={name}
                                  label={label}
                                  fullwidth={fullWidth}
                                  options={ukCounties}
                                  required={fieldRequired}
                                  validate={(value) =>
                                    validateInput(
                                      value,
                                      fieldType,
                                      fieldRequired
                                    )
                                  }
                                />
                              )
                            return null

                          case 'postcode':
                            return (
                              <Field
                                component={Input}
                                type="postcode"
                                key={name}
                                name={name}
                                label={label}
                                fullwidth={fullWidth}
                                required={fieldRequired}
                                validate={(value) => {
                                  if (
                                    value &&
                                    postcodeValidate(country, value) !== true
                                  )
                                    return 'Invalid Postcode'
                                }}
                              />
                            )

                          default:
                            if (!isManualEntry) return null
                            return (
                              <Field
                                component={Input}
                                key={name}
                                name={name}
                                label={label}
                                type={fieldType}
                                fullwidth={fullWidth}
                                required={fieldRequired}
                                validate={(value) =>
                                  validateInput(value, fieldType, fieldRequired)
                                }
                              />
                            )
                        }
                      }
                    )}
                    {automaticAddressCountries.includes(country) && (
                      <Typography
                        variant="bodySmall"
                        onClick={() => {
                          setIsManualEntry(!isManualEntry)
                        }}
                      >
                        {isManualEntry
                          ? 'Find address by postcode '
                          : 'Enter address manually'}
                      </Typography>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={updateSiteContactDetailsLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
    </Container>
  )
}
