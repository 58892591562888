import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardBody,
  Spacing,
} from '@web-applications/daffodil-component-library'

import { UserContext } from './../../util/PageWrapper'

export default function UserDashboard() {
  const user = useContext(UserContext)

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h2">{`Hi ${user.fullName}`}</Typography>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <CardHeader lite>
              <Typography variant="h4">Personal Details</Typography>
            </CardHeader>
            <CardBody>
              <Typography variant="label">Email</Typography>
              <Typography variant="bodySmall">{user.email}</Typography>
              <Spacing multiplier={3} />
              <Button component={Link} to="/edit-user">
                More Details
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
