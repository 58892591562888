import React from "react";

import { Row, Col } from "styled-bootstrap-grid";
import {
  Select,
  Input,
  validateInput,
} from "@web-applications/daffodil-component-library";
import { Field } from "react-final-form";

export default function UserEdit({ archived }) {
  return (
    <>
      <Row>
        <Col md="5">
          <Field
            component={Input}
            name="firstName"
            required
            fullwidth
            label="First Name"
            validate={(value) => validateInput(value, "text", true)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="5">
          <Field
            component={Input}
            name="lastName"
            required
            fullwidth
            label="Last Name"
            validate={(value) => validateInput(value, "text", true)}
          />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Field
            component={Input}
            name="email"
            required
            fullwidth
            label="Email"
            validate={(value) => validateInput(value, "email", true)}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <Field
            component={Select}
            name="roleId"
            label="Role"
            required
            fullwidth
            options={[
              {
                value: 1,
                label: "User",
              },
              {
                value: 5,
                label: "Admin",
              },
            ]}
            format={(value) => {
              const selectedOption = [
                {
                  value: 1,
                  label: "User",
                },
                {
                  value: 5,
                  label: "Admin",
                },
              ].find((option) => option.value === value);

              return selectedOption?.label;
            }}
          />
        </Col>
      </Row>
    </>
  );
}
