import React from 'react'
import { Form, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useQuery, gql } from '@apollo/client'
import arrayMutators from 'final-form-arrays'
import {
  Spacing,
  Button,
  Loading,
  ErrorMessage,
  FlexBox,
  Typography,
  Modal,
  Card,
  CardHeader,
  CardBody,
  Checkbox,
} from '@web-applications/daffodil-component-library'

const GET_CAPTIVE_PORTAL_DETAILS = gql`
  query getCaptivePortalInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      id
      marketingOptions
      name
    }
  }
`

export default function SiteAgreementPopup({
  submitFunction,
  loading: submitLoading,
  siteReference,
}) {
  const { loading, error, data } = useQuery(GET_CAPTIVE_PORTAL_DETAILS, {
    variables: { siteReference },
  })

  const userId = sessionStorage.getItem('userId')

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  //set default values

  const initialValues = {
    marketingOptions: data.getSite.marketingOptions.map(() => false),
  }

  return (
    <Modal>
      <Card>
        <CardHeader>
          <Typography variant="h4">Site Agreements</Typography>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={({ marketingOptions, marketingMethods }) => {
              //format this to be correct

              const marketingTerms = marketingOptions.map((option, index) => ({
                name: data.getSite.marketingOptions[index],
                value: option,
              }))

              submitFunction({
                variables: {
                  marketingOptions: marketingTerms,
                  userId,
                  siteReference,
                  marketingMethods,
                },
              })
            }}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialValues}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="marketingOptions">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Field
                        key={index}
                        component={Checkbox}
                        name={name}
                        type="checkbox"
                        label={data.getSite.marketingOptions[index]}
                      />
                    ))
                  }
                </FieldArray>
                <Typography variant="bodySmall">
                  {`I would like to receive marketing for ${data.getSite.name} using the following:`}
                </Typography>
                <Spacing multiplier={1} />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Call"
                />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Text"
                />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Email"
                />
                <FlexBox>
                  <Button
                    fullwidth
                    loading={submitLoading}
                    disabled={invalid}
                    type="submit"
                  >
                    Continue
                  </Button>
                  <Spacing multiplier={2} />
                </FlexBox>
              </form>
            )}
          />
        </CardBody>
      </Card>
    </Modal>
  )
}
