import React, { useContext, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useToasts } from 'react-toast-notifications'
import {
  Typography,
  Spacing,
  Input,
  Button,
  FlexBox,
  validateInput,
} from '@web-applications/daffodil-component-library'
import { CaptivePortalContext } from './CaptivePortal'

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: EmailAddress!, $loginUrl: String) {
    requestPasswordReset(email: $email, loginUrl: $loginUrl)
  }
`

export default function ForgotPassword() {
  const { addToast } = useToasts()
  const [resetSent, setResetSent] = useState(false)

  const { setCurrentForm } = useContext(CaptivePortalContext)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setResetSent(true)
      },
    }
  )

  if (!resetSent) {
    return (
      <Form
        onSubmit={({ email }) => {
          requestPasswordReset({
            variables: {
              email: email,
              loginUrl: window.location.href,
            },
          })
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container>
              <Row justifyContent="center">
                <Col md="8" lg="5">
                  <Typography variant="h2" center>
                    Password Recovery
                  </Typography>
                  <Spacing multiplier={4} />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col xl="4">
                  <Field
                    component={Input}
                    name="email"
                    required
                    fullwidth
                    label="Email Address"
                    type="email"
                    autoComplete
                    validate={(value) => validateInput(value, 'email', true)}
                  />
                  <Spacing multiplier={1} />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col xl="4">
                  <Button
                    fullwidth
                    loading={loading}
                    disabled={pristine || invalid}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                  <Spacing multiplier={3} />
                  <Typography
                    variant="label"
                    onClick={() => {
                      setCurrentForm('login')
                    }}
                    center
                  >
                    Back To Login
                  </Typography>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      />
    )
  } else {
    return (
      <Container>
        <Row justifyContent="center">
          <Col xl="6">
            <Typography variant="h5" center>
              Thank You!
            </Typography>
            <Spacing multiplier={6} />
            <Typography variant="bodySmall" colour="#626C79" center>
              If you have an account, we'll email you a reset link.
            </Typography>
            <Spacing multiplier={2} />
            <Typography variant="bodySmall" colour="#626C79" center>
              If no email is received within five minutes, check the email
              address you submitted is correct and try again.
            </Typography>
            <Spacing multiplier={5} />
            <FlexBox justifyContent="center">
              <Button
                onClick={() => {
                  setCurrentForm('login')
                }}
              >
                Back To Login
              </Button>
            </FlexBox>
          </Col>
        </Row>
      </Container>
    )
  }
}
