import React, { useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { UserContext } from "../../../util/PageWrapper";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  Loading,
  Typography,
  Card,
  Button,
  Spacing,
  FlexBox,
} from "@web-applications/daffodil-component-library";

import { Add, RemoveRedEye } from "@material-ui/icons";
import { orange, lightGreen } from "@material-ui/core/colors";
import { IconButton, Tooltip } from "@material-ui/core";

const secondary = lightGreen[500];
const primary = orange[500];

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      fullName
      email
      archived
      roleName
    }
  }
`;

const columnWidthTheme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:last-child": {
          width: 100,
          padding: "4px 24px",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
      responsiveBase: {
        zIndex: 1,
      },
    },
    MUIDataTablePagination: {
      root: {
        borderBottom: "0",
      },
    },
  },
});

export default function UserList() {
  const user = useContext(UserContext);
  const { loading, error, data } = useQuery(GET_USERS);

  if (loading) {
    return <Loading />;
  }

  if (error || user.roleId < 5) {
    return null;
  }

  let { getUsers } = data;

  const formattedUsersArray = [];

  getUsers.forEach((user) => {
    const data = [
      {
        columnDetails: {
          name: "Name",
          options: {
            filter: false,
            sort: true,
            download: true,
          },
        },
        columnData: `${user.fullName}`,
      },
      {
        columnDetails: {
          name: "Role",
          options: {
            filter: true,
            sort: true,
            download: true,
          },
        },
        columnData: user.roleName,
      },
      {
        columnDetails: {
          name: "Email",
          options: {
            filter: false,
            sort: true,
            download: true,
          },
        },
        columnData: user.email,
      },

      {
        columnDetails: {
          name: "Archived",
          options: {
            filter: true,
            sort: true,
            download: true,
            display: "false",
          },
        },
        columnData: user.archived ? "Yes" : "No",
      },
      {
        columnDetails: {
          name: "Actions",
          options: {
            filter: false,
            sort: false,
            download: false,
            display: "true",
          },
        },
        columnData: (
          <Tooltip title="View User Details" placement="top">
            <IconButton
              aria-label="View"
              component={Link}
              to={"user/edit/" + user.id}
            >
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        ),
      },
    ];

    formattedUsersArray.push(data);
  });

  const columnsArray = [];
  const userData = [];

  formattedUsersArray.forEach((columns, index) => {
    const columnData = [];

    columns.forEach((column) => {
      columnData.push(column.columnData);
    });

    userData.push(columnData);

    //only create column titles on first pass
    if (index === 0) {
      columns.forEach((column) => {
        columnsArray.push(column.columnDetails);
      });
    }
  });

  const options = {
    elevation: 0,
    filterType: "checkbox",
    selectableRows: "none",
    print: false,
    responsive: "standard",
    downloadOptions: {
      filename: "Users.csv",
    },
  };

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <FlexBox justifyContent="space-between">
            <Typography variant="h2">Users</Typography>
            <Button
              component={Link}
              to="users/add"
              icon={<Add style={{ marginRight: "10px" }} />}
            >
              New User
            </Button>
          </FlexBox>

          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <MuiThemeProvider theme={columnWidthTheme}>
              <MUIDataTable
                title=""
                data={userData}
                columns={columnsArray}
                options={options}
              />
            </MuiThemeProvider>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
