import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import {
  ErrorMessage,
  Loading,
  Card,
  Spacing,
  CardBody,
  Typography,
  Tabs,
  CardHeader,
  getCountryName,
  Button,
} from '@web-applications/daffodil-component-library'
import groupArray from 'group-array'

const GET_SITES = gql`
  query getSites {
    getSites {
      id
      address {
        line1
        line2
        city
        county
        country
        postcode
      }
      name
      reference
      business {
        id
        name
      }
    }
  }
`

export default function BusinessManagerDashboard() {
  const [selectedBusiness, setSelectedBusiness] = useState(0)

  const { loading, error, data } = useQuery(GET_SITES)

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  // split sites by business

  let businessSites = groupArray(data.getSites, 'business.name')

  businessSites = Object.values(businessSites).map((business) => {
    //get an array of the sites from this array
    const sites = business.map((site) => {
      const { business, ...siteDetails } = site

      return siteDetails
    })

    return {
      name: business[0].business.name,
      id: business[0].id,
      sites,
    }
  })

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={4} />
          <Typography variant="h1">My Sites</Typography>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            tabs={businessSites.map(({ name }, index) => ({
              label: name,
              active: selectedBusiness === index,
              onClick: () => setSelectedBusiness(index),
            }))}
          />
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        {businessSites[selectedBusiness].sites.map(
          ({ id, address, name, reference }) => (
            <Col md={4} key={id}>
              <Card>
                <CardHeader lite>
                  <Typography variant="h4"> {name}</Typography>
                </CardHeader>
                <CardBody>
                  <Typography variant="label">Address</Typography>
                  <Typography variant="bodySmall">
                    {`${address.line1}, ${
                      !!address.line2 ? address.line2 + ', ' : ''
                    }${address.city}, ${address.county}, ${getCountryName(
                      address.country
                    )}, ${address.postcode}`}
                  </Typography>
                  <Spacing multiplier={3} />
                  <Button component={Link} to={`/site/${reference}`}>
                    Manage Site
                  </Button>
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  )
}
