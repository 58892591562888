import React from "react";
import { Router } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import WebFont from "webfontloader";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";
import {
  ThemeProvider,
  Toast,
} from "@web-applications/daffodil-component-library";
import { ToastProvider } from "react-toast-notifications";
import { onError } from "@apollo/client/link/error";

import theme from "./assets/ThemeStyling";
import "@web-applications/daffodil-component-library/build/index.css";

import Routes from "./routes";
import Popup from "./components/reusable/Popup";

// connect to sentry for error tracking
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

const client = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.extensions.code === "UNAUTHENTICATED") {
            Cookies.remove("dwifi");
          } else if (error.extensions.code !== "BAD_USER_INPUT") {
            Sentry.captureException(error.message);
          }

          //only log errors if not on live
          if (process.env.REACT_APP_SENTRY_ENV !== "live")
            console.error(`[GraphQl error]: ${error.message}`);
        });
      }
      if (networkError && process.env.REACT_APP_SENTRY_ENV !== "live")
        console.error(`[Network error]: ${networkError}`);
    }),
    createUploadLink({
      uri: `${process.env.REACT_APP_SERVER_URL}`,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
});

const history = createBrowserHistory();

ReactGA.initialize(process.env.REACT_APP_GA, {
  testMode: process.env.REACT_APP_SENTRY_ENV === "local",
});

// record initial load page view
ReactGA.pageview(window.location.pathname);

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

// load webfonts
WebFont.load({
  google: {
    families: ["Roboto:400", "Poppins:400,500,600"],
  },
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider
            components={{ Toast: Toast }}
            autoDismissTimeout={6000}
            autoDismiss
            transitionDuration={300}
          >
            <Router history={history}>
              <Popup />
              {Routes}
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
}
