import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import {
  ErrorMessage,
  Loading,
  Card,
  Spacing,
  CardBody,
  Typography,
  CardHeader,
  getCountryName,
  Button,
} from "@web-applications/daffodil-component-library";

const GET_SITES = gql`
  query getSites {
    getSites {
      id
      address {
        line1
        line2
        city
        county
        country
        postcode
      }
      name
      reference
    }
  }
`;

export default function SiteManagerDashboard() {
  const { loading, error, data } = useQuery(GET_SITES);

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={2} />
          <Typography variant="h2">My Sites</Typography>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        {data.getSites.map(({ id, address, name, reference }) => (
          <Col md={4} key={id}>
            <Card>
              <CardHeader lite>
                <Typography variant="h4"> {name}</Typography>
              </CardHeader>
              <CardBody>
                <Typography variant="label">Address</Typography>
                <Typography variant="bodySmall">
                  {`${address.line1}, ${
                    !!address.line2 ? address.line2 + ", " : ""
                  }${address.city}, ${address.county}, ${getCountryName(
                    address.country
                  )}, ${address.postcode}`}
                </Typography>
                <Spacing multiplier={3} />
                <Button component={Link} to={`/site/${reference}`}>
                  Manage Site
                </Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
