import React, { useState, useEffect, createContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { useToasts } from "react-toast-notifications";
import {
  Spacing,
  Loading,
  ErrorMessage,
  Typography,
} from "@web-applications/daffodil-component-library";
import { parse } from "query-string";
import { useModal } from "react-modal-hook";
import SiteAgreementPopup from "./SiteAgreementPopup";
import Register from "./Register";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

const GET_CAPTIVE_PORTAL_DETAILS = gql`
  query getCaptivePortalInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      id
      logo
      introText
      marketingOptions
      redirectUrl
      language
      updatedDate
      introText
      hiddenFields
    }
  }
`;

const REGISTER_ACCESS_POINT = gql`
  mutation registerAccessPoint(
    $macAddress: MAC!
    $ssid: String!
    $siteReference: String!
  ) {
    registerAccessPoint(
      macAddress: $macAddress
      ssid: $ssid
      siteReference: $siteReference
    ) {
      id
    }
  }
`;

const CREATE_SITE_AGREEMENT = gql`
  mutation createSiteAgreement(
    $marketingOptions: [JSONObject]!
    $userId: ID!
    $siteReference: String!
    $marketingMethods: [String]
  ) {
    createSiteAgreement(
      marketingOptions: $marketingOptions
      userId: $userId
      siteReference: $siteReference
      marketingMethods: $marketingMethods
    )
  }
`;

export const CaptivePortalContext = createContext();

export default function CaptivePortal({ location: { search } }) {
  const [currentForm, setCurrentForm] = useState("login");
  const [redirectUrl, setRedirectUrl] = useState();

  const { addToast } = useToasts();

  const [createSiteAgreement, { loading: createSiteAgreementLoading }] =
    useMutation(CREATE_SITE_AGREEMENT, {
      onCompleted: () => {
        hideAgreementPopup();
        sessionStorage.clear();
        window.location.replace(redirectUrl);
      },
      onError: () => {
        addToast("Something went wrong, please try again.", {
          appearance: "error",
        });
      },
    });

  const [
    registerAccessPoint,
    {
      loading: registerAccessPointLoading,
      error: registerAccessPointError,
      data: registerAccessPointData,
    },
  ] = useMutation(REGISTER_ACCESS_POINT);

  const [showAgreementPopup, hideAgreementPopup] = useModal(() => (
    <SiteAgreementPopup
      submitFunction={createSiteAgreement}
      loading={createSiteAgreementLoading}
      siteReference={siteReference}
    />
  ));

  // get parameters from url
  const { siteReference, clientMac, loginUrl, apMac, ssid } = parse(search);

  useEffect(() => {
    registerAccessPoint({
      variables: {
        macAddress: apMac,
        ssid,
        siteReference,
      },
    });
  }, [registerAccessPoint, apMac, ssid, siteReference]);

  const { loading, error, data } = useQuery(GET_CAPTIVE_PORTAL_DETAILS, {
    variables: { siteReference },
  });

  if (loading || registerAccessPointLoading) return <Loading />;

  if (error || registerAccessPointError)
    return <ErrorMessage error={error || registerAccessPointError} />;

  let content;

  switch (currentForm) {
    case "register":
      content = <Register />;
      break;
    case "forgot-password":
      content = <ForgotPassword />;
      break;
    case "login":
    default:
      content = <Login />;
      break;
  }

  return (
    <CaptivePortalContext.Provider
      value={{
        setRedirectUrl: setRedirectUrl,
        showAgreementPopup: showAgreementPopup,
        siteReference: siteReference,
        clientMac: clientMac,
        loginUrl: loginUrl,
        registerAccessPointData: registerAccessPointData,
        setCurrentForm: setCurrentForm,
        hiddenFields: data.getSite.hiddenFields,
      }}
    >
      <Container>
        <Row justifyContent="center">
          <Col md="9" lg="6">
            <Spacing multiplier={4} />
            <img
              src={data.getSite.logo}
              alt="Site Logo"
              width="100%"
              height="150px"
            />
            <Spacing multiplier={4} />
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col md="8" lg="5">
            <Typography variant="bodySmall" center>
              {data.getSite.introText}
            </Typography>
            <Spacing multiplier={4} />
          </Col>
        </Row>
      </Container>
      {content}
    </CaptivePortalContext.Provider>
  );
}
