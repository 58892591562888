import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import gql from 'graphql-tag'
import { useToasts } from 'react-toast-notifications'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { validate as postcodeValidate } from 'postal-codes-js'
import Icon from '@mdi/react'
import { mdiContentSave } from '@mdi/js'
import { parseISO } from 'date-fns'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import {
  ErrorMessage,
  Loading,
  Card,
  CardHeader,
  CardBody,
  Input,
  Typography,
  DropdownLookup,
  CardFooter,
  Select,
  DatePicker,
  Button,
  validateInput,
  validatePhoneNumber,
  getCountryNames,
  Spacing,
} from '@web-applications/daffodil-component-library'

import { UserContext } from './../../util/PageWrapper'

const GET_USER = gql`
  query getUser {
    getUser {
      id
      firstName
      lastName
      email
      dateOfBirth
      gender
      postcode
      mobile
      country
    }
  }
`

const UPDATE_USER_CONTACT_DETAILS = gql`
  mutation updateUserContactDetails(
    $userId: ID!
    $email: EmailAddress!
    $postcode: String
    $mobile: String
    $country: String!
  ) {
    updateUserContactDetails(
      userId: $userId
      email: $email
      postcode: $postcode
      mobile: $mobile
      country: $country
    ) {
      id
      email
      postcode
      mobile
      country
    }
  }
`

const UPDATE_USER_PERSONAL_DETAILS = gql`
  mutation updateUserPersonalDetails(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: DateTime
    $gender: String
  ) {
    updateUserPersonalDetails(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      gender: $gender
    ) {
      id
      firstName
      lastName
      dateOfBirth
      gender
      fullName
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($userId: ID!, $password: String!) {
    resetPassword(userId: $userId, password: $password)
  }
`

const countriesList = getCountryNames()

//format countries list for dropdown
const formattedCountryList = countriesList.map(({ code, name }) => ({
  value: code,
  label: name,
}))

export default function UserDetails() {
  const { addToast } = useToasts()
  const { id: userId } = useContext(UserContext)

  const [
    updateUserContactDetails,
    { updateUserContactDetailsLoading },
  ] = useMutation(UPDATE_USER_CONTACT_DETAILS, {
    onCompleted: () => {
      addToast('Your contact details have been updated', {
        appearance: 'success',
      })
    },
    onError: () => {
      addToast('Something went wrong, please try again.', {
        appearance: 'error',
      })
    },
  })

  const [
    updateUserPersonalDetails,
    { updateUserPersonalDetailsLoading },
  ] = useMutation(UPDATE_USER_PERSONAL_DETAILS, {
    onCompleted: () => {
      addToast('Your personal details have been updated', {
        appearance: 'success',
      })
    },
    onError: () => {
      addToast('Something went wrong, please try again.', {
        appearance: 'error',
      })
    },
  })

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        addToast('Password updated', {
          appearance: 'success',
        })
      },
    }
  )

  const { loading, error, data } = useQuery(GET_USER)

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const user = data.getUser

  //calculate user mobile

  let userMobile

  if (user.mobile) {
    const parsedNumber = parsePhoneNumberFromString(user.mobile)

    userMobile = parsedNumber
      .format('IDD', {
        fromCountry: user.country,
        humanReadable: true,
      })
      .replace(' ', '')
  }

  return (
    <Container>
      <Row>
        <Col xd={12} md={6}>
          <Form
            onSubmit={({ firstName, lastName, dateOfBirth, gender }) => {
              updateUserPersonalDetails({
                variables: {
                  userId,
                  firstName,
                  lastName,
                  dateOfBirth,
                  gender,
                },
              })
            }}
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              dateOfBirth: user.dateOfBirth
                ? parseISO(user.dateOfBirth)
                : undefined,
              gender: user.gender,
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Typography variant="h4">Personal Details</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="firstName"
                      required
                      label="First Name"
                      validate={(value) => validateInput(value, 'text', true)}
                    />
                    <Field
                      component={Input}
                      name="lastName"
                      required
                      label="Last Name"
                      validate={(value) => validateInput(value, 'text', true)}
                    />
                    <Field
                      component={DatePicker}
                      name="dateOfBirth"
                      label="Date Of Birth"
                      showYearDropdown
                      maxDate={new Date()}
                    />
                    <Field
                      component={Select}
                      name="gender"
                      label="Gender"
                      options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                      ]}
                      format={(value) => {
                        const selectedOption = [
                          { value: 'Male', label: 'Male' },
                          { value: 'Female', label: 'Female' },
                        ].find((option) => option.value === value)

                        return selectedOption?.label
                      }}
                    />
                  </CardBody>
                  <CardFooter lite>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={updateUserPersonalDetailsLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form
            onSubmit={({ email, country, mobile, postcode }) => {
              return updateUserContactDetails({
                variables: {
                  userId,
                  email,
                  country,
                  mobile,
                  postcode,
                },
              }).catch(({ graphQLErrors }) => {
                //If there waws auser input error, update the field with the error, else just show generic error message
                if (graphQLErrors[0].extensions.code === 'BAD_USER_INPUT')
                  return {
                    [graphQLErrors[0].extensions.invalidArgs]:
                      graphQLErrors[0].message,
                  }

                addToast('Failed to update your profile, please try again', {
                  appearance: 'error',
                })
              })
            }}
            initialValues={{
              email: user.email,
              country: user.country,
              mobile: userMobile,
              postcode: user.postcode,
            }}
            validate={({ mobile, postcode, country }) => {
              const errors = {}

              if (mobile && country)
                errors.mobile = validatePhoneNumber(mobile, country)

              if (
                postcode &&
                country &&
                postcodeValidate(country, postcode) !== true
              )
                errors.postcode = 'Invalid Postcode'

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Typography variant="h4">Contact Details</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="email"
                      required
                      label="Email Address"
                      type="email"
                      autoComplete
                      validate={(value) => validateInput(value, 'email', true)}
                    />
                    <Field
                      component={Input}
                      name="mobile"
                      type="number"
                      label="Mobile"
                    />
                    <Field
                      component={DropdownLookup}
                      name="country"
                      label="Country"
                      options={formattedCountryList}
                      required
                      validate={(value) =>
                        validateInput(value, 'dropdownLookup', true)
                      }
                    />
                    <Field
                      component={Input}
                      type="postcode"
                      name="postcode"
                      label="Postcode"
                    />
                  </CardBody>
                  <CardFooter lite>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={updateUserContactDetailsLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacing multiplier={3} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form
            onSubmit={({ confirmPassword }) => {
              resetPassword({
                variables: { userId: userId, password: confirmPassword },
              })
            }}
            validate={({ newPassword, confirmPassword }) => {
              let errors = {}

              if (confirmPassword && confirmPassword !== newPassword) {
                errors.confirmPassword = 'Passwords Must Match'
              }

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Typography variant="h4">Change Password</Typography>
                  </CardHeader>
                  <CardBody>
                    <Field
                      component={Input}
                      name="newPassword"
                      required
                      fullwidth
                      label="New Password"
                      type="password"
                      autoComplete
                      validate={(value) =>
                        validateInput(value, 'password', true)
                      }
                    />
                    <Field
                      component={Input}
                      name="confirmPassword"
                      required
                      fullwidth
                      label="Confirm Password"
                      type="password"
                      autoComplete
                      validate={(value) =>
                        validateInput(value, 'password', true)
                      }
                    />
                  </CardBody>
                  <CardFooter lite>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={resetPasswordLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Update Password
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
    </Container>
  )
}
