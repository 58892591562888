import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import {
  Typography,
  Spacing,
  Input,
  Checkbox,
  FlexBox,
  Button,
  validateInput,
} from '@web-applications/daffodil-component-library'

//image
import Logo from './../assets/images/Daffodil-WIFI-Logo-Dark.png'

const LOGIN = gql`
  mutation loginUser(
    $email: EmailAddress!
    $password: String!
    $rememberMe: Boolean
  ) {
    loginUser(email: $email, password: $password, rememberMe: $rememberMe) {
      id
      fullName
    }
  }
`

export default function Login({ history }) {
  const [loginUser, { loading, client }] = useMutation(LOGIN, {
    onCompleted: () => {
      client.resetStore()
      history.push('/')
    },
  })

  //redirect user to home page if they try to login whilst already logged in

  if (Cookies.get('dwifi')) history.push('/')

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      <Typography variant="h5" center>
        Enter your login details below
      </Typography>
      <Spacing multiplier={6} />
      <Form
        onSubmit={async ({ email, password, rememberMe }) => {
          const response = await loginUser({
            variables: {
              email: email,
              password: password,
              rememberMe: !!rememberMe || false,
            },
          }).catch(() => {
            return {
              email: 'Incorrect email or password',
              password: 'Incorrect email or password',
            }
          })

          if (!response.data) return response
        }}
        render={({ handleSubmit, pristine, invalid, dirtySinceLastSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container>
              <Row justifyContent="center">
                <Col xl="4">
                  <Field
                    component={Input}
                    name="email"
                    required
                    fullwidth
                    label="Email Address"
                    type="email"
                    autoComplete
                    validate={(value) => validateInput(value, 'email', true)}
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col xl="4">
                  <Field
                    component={Input}
                    name="password"
                    required
                    fullwidth
                    label="Password"
                    type="password"
                    autoComplete
                    validate={(value) => validateInput(value, 'password', true)}
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col xl="4">
                  <FlexBox
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Field
                      component={Checkbox}
                      name="rememberMe"
                      type="checkbox"
                      value="Remember Me"
                    />
                    <Typography
                      variant="label"
                      component={Link}
                      to="forgotten-password"
                    >
                      Forgot My Password
                    </Typography>
                  </FlexBox>
                  <Spacing multiplier={2} />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col xl="4">
                  <Button
                    fullwidth
                    loading={loading}
                    disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                    type="submit"
                  >
                    Log In
                  </Button>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      />
    </Fragment>
  )
}
