import React, { Fragment, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useToasts } from "react-toast-notifications";

import {
  Typography,
  Button,
} from "@web-applications/daffodil-component-library";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { closeModal, openModal } from "../../../components/reusable/Popup";

import { Clear } from "@material-ui/icons";

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(email: $email)
  }
`;

export default function ResetPassword({ email }) {
  const { addToast } = useToasts();
  const [resetSent, setResetSent] = useState(false);

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: () => {
        addToast("Something went wrong, please try again.", {
          appearance: "error",
        });
      },
      onCompleted: () => {
        setResetSent(true);
        closeModal();
      },
    }
  );

  return (
    <Fragment>
      {!resetSent ? (
        <Button
          variant="contained"
          loading={loading}
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Send reset email?"
                  />
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to send this user a password reset
                      email?
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        requestPasswordReset({
                          variables: {
                            email: email,
                          },
                        });
                      }}
                    >
                      Continue
                    </Button>
                  </CardActions>
                </Card>
              ),
            });
          }}
        >
          Reset Password
        </Button>
      ) : (
        <Typography variant="h5" center>
          Reset email sent
        </Typography>
      )}
    </Fragment>
  );
}
