import React, { useContext } from "react";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  ErrorMessage,
  Loading,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  validateInput,
  Input,
  UploadWrapper,
  Spacing,
  IconButton,
  FlexBox,
  Checkbox,
} from "@web-applications/daffodil-component-library";
import { useToasts } from "react-toast-notifications";
import { format, parseISO } from "date-fns";
import Icon from "@mdi/react";
import {
  mdiContentSave,
  mdiCloudUpload,
  mdiPlusCircle,
  mdiClose,
  mdiCloudDownload,
} from "@mdi/js";
import styled, { ThemeContext } from "styled-components";

const EDIT_CAPTIVE_PORTAL = gql`
  mutation editCaptivePortal(
    $id: ID!
    $logo: Upload
    $introText: String!
    $marketingOptions: [String]!
    $redirectUrl: String!
    $language: String!
    $hiddenFields: [String]!
  ) {
    editCaptivePortal(
      id: $id
      logo: $logo
      introText: $introText
      marketingOptions: $marketingOptions
      redirectUrl: $redirectUrl
      language: $language
      hiddenFields: $hiddenFields
    ) {
      id
      logo
      name
      introText
      marketingOptions
      redirectUrl
      language
      hiddenFields
      updatedDate
    }
  }
`;

const GET_CAPTIVE_PORTAL_DETAILS = gql`
  query getSiteInformation($siteReference: String!) {
    getSite(siteReference: $siteReference) {
      id
      logo
      name
      introText
      marketingOptions
      redirectUrl
      language
      updatedDate
      hiddenFields
    }
  }
`;

const DOWNLOAD_SITE_CONTROLLER_FILES = gql`
  query downloadSiteControllerFiles($siteReference: String!) {
    downloadSiteControllerFiles(siteReference: $siteReference)
  }
`;

const RemoveRow = styled.div`
  margin-bottom: 32px;
  display: flex;
`;

const formDecorators = createDecorator({
  //get data uri from uploaded image
  field: "logo",
  updates: async (value) => {
    const imageData = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(value[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
    });

    return {
      logo: value,
      image: await imageData,
    };
  },
});

export default function EditCaptivePortal({
  match: {
    params: { siteReference },
  },
}) {
  const { addToast } = useToasts();

  const {
    colours: { slate },
  } = useContext(ThemeContext);

  const [editCaptivePortal, { loading: editCaptivePortalLoading }] =
    useMutation(EDIT_CAPTIVE_PORTAL, {
      onCompleted: ({ editCaptivePortal: { name } }) => {
        addToast(`${name} has been updated.`, {
          appearance: "success",
        });
      },
      onError: () => {
        addToast("Something went wrong, please try again.", {
          appearance: "error",
        });
      },
    });

  const [
    downloadSiteControllerFiles,
    { loading: downloadSiteControllerFilesLoading },
  ] = useLazyQuery(DOWNLOAD_SITE_CONTROLLER_FILES, {
    fetchPolicy: "network-only",
    onError: () => {
      addToast("Something went wrong, please try again.", {
        appearance: "error",
      });
    },
    onCompleted: ({ downloadSiteControllerFiles: fileUrl }) => {
      window.location.href = fileUrl;
    },
  });

  const { loading, error, data } = useQuery(GET_CAPTIVE_PORTAL_DETAILS, {
    variables: { siteReference },
  });

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  const {
    updatedDate,
    logo,
    id: siteId,
    introText,
    marketingOptions,
    redirectUrl,
    language,
    hiddenFields,
  } = data.getSite;

  return (
    <Form
      onSubmit={({
        logo,
        introText,
        marketingOptions,
        redirectUrl,
        language,
        hiddenFields,
      }) => {
        editCaptivePortal({
          variables: {
            id: siteId,
            logo: logo ? logo[0] : undefined,
            introText,
            marketingOptions,
            redirectUrl,
            language,
            hiddenFields,
          },
        });
      }}
      initialValues={{
        introText,
        marketingOptions,
        redirectUrl,
        language,
        hiddenFields,
      }}
      decorators={[formDecorators]}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        pristine,
        invalid,
        values,
        values: { image },
      }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col>
                <Spacing multiplier={2} />
                <FlexBox justifyContent="space-between">
                  <div>
                    <Typography variant="h4">Captive Portal Details</Typography>
                    <Typography variant="bodySmall" colour="#1C1C26">
                      {"Last Updated: " +
                        format(parseISO(updatedDate), "do MMMM y 'at' HH:mm")}
                    </Typography>
                  </div>
                  <Button
                    type="button"
                    icon={
                      <Icon
                        path={mdiCloudDownload}
                        title="Upload"
                        size="24px"
                      />
                    }
                    onClick={() => {
                      downloadSiteControllerFiles({
                        variables: {
                          siteReference,
                        },
                      });
                    }}
                    loading={downloadSiteControllerFilesLoading}
                  >
                    Download Controller Files
                  </Button>
                </FlexBox>
                <Spacing multiplier={5} />
                <Card>
                  <CardBody container>
                    <Row>
                      <Col xs={12}>
                        <Typography variant="label">Welcome Image</Typography>
                        <img
                          src={image ? image : logo}
                          alt="Site Logo"
                          height="150px"
                          width="100%"
                        />
                        <Spacing multiplier={4} />
                        <Field name="logo">
                          {({ input }) => (
                            <UploadWrapper
                              fileTypes="image/jpeg, image/png"
                              noDrag
                              multiple={false}
                              {...input}
                            >
                              <Button
                                type="button"
                                icon={
                                  <Icon
                                    path={mdiCloudUpload}
                                    title="Upload"
                                    size="24px"
                                  />
                                }
                              >
                                Upload
                              </Button>
                            </UploadWrapper>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Spacing multiplier={3} />
                        <Field
                          component={Input}
                          name="redirectUrl"
                          required
                          fullwidth
                          label="Redirect Url"
                          validate={(value) =>
                            validateInput(value, "text", true)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Field
                          component={Input}
                          name="introText"
                          required
                          fullwidth
                          type="textarea"
                          rows="4"
                          label="Intro Text"
                          validate={(value) =>
                            validateInput(value, "text", true)
                          }
                        />
                      </Col>

                      <Col xs={12}>
                        <FieldArray name="marketingOptions">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => (
                                <FlexBox key={name} alignItems="flex-end">
                                  <Field
                                    component={Input}
                                    name={name}
                                    required
                                    fullwidth
                                    label={index ? "" : "Marketing Options"}
                                    validate={(value) =>
                                      validateInput(value, "text", true)
                                    }
                                  />

                                  {fields.length > 1 && (
                                    <RemoveRow>
                                      <Spacing multiplier={2} />
                                      <IconButton
                                        path={mdiClose}
                                        title="Delete"
                                        size="24px"
                                        colour={slate}
                                        onClick={() => fields.remove(index)}
                                      />
                                    </RemoveRow>
                                  )}
                                </FlexBox>
                              ))}
                              <FlexBox inline onClick={() => fields.push("")}>
                                <Icon
                                  path={mdiPlusCircle}
                                  title="Add Row"
                                  size="20px"
                                  color={slate}
                                />
                                <Spacing multiplier={1} />
                                <Typography
                                  variant="bodySmall"
                                  colour="#626C79"
                                >
                                  Add another marketing option
                                </Typography>
                              </FlexBox>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <Row>
                      <Spacing multiplier={3} />
                      <Col xs={12}>
                        <Typography variant="label">
                          Fields to hide during registration
                        </Typography>
                        <Spacing multiplier={1} />
                        <Field
                          component={Checkbox}
                          name="hiddenFields"
                          type="checkbox"
                          value="Date of birth"
                        />
                        <Field
                          component={Checkbox}
                          name="hiddenFields"
                          type="checkbox"
                          value="Gender"
                        />
                        <Field
                          component={Checkbox}
                          name="hiddenFields"
                          type="checkbox"
                          value="Country"
                        />
                        <Field
                          component={Checkbox}
                          name="hiddenFields"
                          type="checkbox"
                          value="Mobile"
                        />
                        <Field
                          component={Checkbox}
                          name="hiddenFields"
                          type="checkbox"
                          value="Postcode"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  <CardFooter>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={editCaptivePortalLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    />
  );
}
