import React, { useContext } from 'react'

import { UserContext } from './../util/PageWrapper'

import UserDashboard from './Dashboards/UserDashboard'
import SiteManagerDashboard from './Dashboards/SiteManagerDashboard'
import BusinessManagerDashboard from './Dashboards/BusinessManagerDashboard'
import AdminDashboard from './Dashboards/AdminDashboard'

// Role of this component is to display the relevant dasboard for the user
export default function Home() {
  const { roleId } = useContext(UserContext)

  switch (roleId) {
    case 1:
    default:
      return <UserDashboard />
    case 2:
      return <SiteManagerDashboard />
    case 3:
      return <BusinessManagerDashboard />
    case 5:
      return <AdminDashboard />
  }
}
