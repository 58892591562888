import React, { useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import {
  Spacing,
  Input,
  Button,
  validateInput,
  FlexBox,
  Typography,
} from '@web-applications/daffodil-component-library'
import platform from 'platform'
import { CaptivePortalContext } from './CaptivePortal'

const LOGIN_WIFI_USER = gql`
  mutation loginWifiUser(
    $email: EmailAddress!
    $password: String!
    $siteReference: String!
    $clientMacAddress: MAC!
    $deviceOS: String!
    $loginUrl: String!
    $browser: String!
    $accessPointId: ID!
  ) {
    loginWifiUser(
      email: $email
      password: $password
      siteReference: $siteReference
      clientMacAddress: $clientMacAddress
      deviceOS: $deviceOS
      loginUrl: $loginUrl
      browser: $browser
      accessPointId: $accessPointId
    ) {
      hasAgreed
      redirectUrl
      userId
    }
  }
`

export default function Login() {
  const {
    setRedirectUrl,
    showAgreementPopup,
    siteReference,
    clientMac,
    loginUrl,
    registerAccessPointData,
    setCurrentForm,
  } = useContext(CaptivePortalContext)

  const [loginWifiUser, { loading: loadingWifiLogin }] = useMutation(
    LOGIN_WIFI_USER,
    {
      onCompleted: ({ loginWifiUser: { redirectUrl, hasAgreed, userId } }) => {
        if (hasAgreed) {
          window.location.replace(redirectUrl)
        } else {
          sessionStorage.setItem('userId', userId)

          setRedirectUrl(redirectUrl)
          showAgreementPopup()
        }
      },
    }
  )

  return (
    <Form
      onSubmit={async ({ email, password }) => {
        const response = await loginWifiUser({
          variables: {
            email: email,
            password: password,
            siteReference,
            clientMacAddress: clientMac,
            deviceOS: platform.os.family + ' ' + platform.os.version,
            loginUrl,
            browser: platform.name || 'Unknown',
            accessPointId: registerAccessPointData.registerAccessPoint.id,
          },
        }).catch(() => {
          return {
            email: 'Incorrect email or password',
            password: 'Incorrect email or password',
          }
        })

        if (!response.data) return response
      }}
      render={({ handleSubmit, pristine, invalid, dirtySinceLastSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Container>
            <Row justifyContent="center">
              <Col md="8" lg="5">
                <Button
                  fullwidth
                  type="button"
                  onClick={() => {
                    setCurrentForm('register')
                  }}
                >
                  Register
                </Button>
                <Spacing multiplier={3} />
                <Typography variant="bodySmall" center>
                  or
                </Typography>
                <Spacing multiplier={3} />
              </Col>
            </Row>

            <Row justifyContent="center">
              <Col md="8" lg="5">
                <Typography variant="h2" center>
                  Login
                </Typography>
                <Spacing multiplier={4} />
                <Field
                  component={Input}
                  name="email"
                  required
                  fullwidth
                  label="Email Address"
                  type="email"
                  autoComplete
                  validate={(value) => validateInput(value, 'email', true)}
                />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="8" lg="5">
                <Field
                  component={Input}
                  name="password"
                  required
                  fullwidth
                  label="Password"
                  type="password"
                  autoComplete
                  validate={(value) => validateInput(value, 'password', true)}
                />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="8" lg="5">
                <FlexBox justifyContent="space-between" alignItems="flex-start">
                  <Typography
                    variant="label"
                    onClick={() => {
                      setCurrentForm('forgot-password')
                    }}
                  >
                    Forgot My Password
                  </Typography>
                </FlexBox>
                <Spacing multiplier={2} />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="8" lg="5">
                <Button
                  fullwidth
                  loading={loadingWifiLogin}
                  disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                  type="submit"
                >
                  Log In
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    />
  )
}
