import React, { useContext } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../util/PageWrapper";

import { useToasts } from "react-toast-notifications";
import { Form, Field } from "react-final-form";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  Spacing,
  Input,
  Button,
  validateInput,
  Typography,
  Card,
  CardBody,
} from "@web-applications/daffodil-component-library";

import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";

const REGISTER_WIFI_USER_BY_ADMIN = gql`
  mutation createUserByAdmin(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $password: String!
  ) {
    createUserByAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      id
      fullName
    }
  }
`;

export default function Register() {
  const user = useContext(UserContext);
  const { addToast } = useToasts();
  const history = useHistory();

  const [createUserByAdmin, { loading: createUserByAdminLoading }] =
    useMutation(REGISTER_WIFI_USER_BY_ADMIN, {
      onCompleted: ({ createUserByAdmin }) => {
        addToast(
          `User ${createUserByAdmin.fullName} has been successfully created`,
          {
            appearance: "success",
          }
        );
        history.push(`../user/edit/${createUserByAdmin.id}`);
      },
    });

  if (user.roleId < 5) {
    return null;
  }

  return (
    <Form
      initialValues={{}}
      onSubmit={({ firstName, lastName, email, confirmPassword }) => {
        const response = createUserByAdmin({
          variables: {
            firstName,
            lastName,
            email,
            password: confirmPassword,
          },
        }).catch((error) => {
          if (error.message === "Email already in use") {
            return {
              email: "Email already in use",
            };
          } else {
            addToast("Failed to create user, please try again", {
              appearance: "error",
            });
          }
        });

        if (!response.data) return response;
      }}
      validate={({ confirmPassword, password }) => {
        const errors = {};

        if (confirmPassword && confirmPassword !== password) {
          errors.confirmPassword = "Passwords Must Match";
        }

        return errors;
      }}
      render={({
        values,
        handleSubmit,
        pristine,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Container>
            <Row>
              <Col>
                <Spacing multiplier={2} />
                <Typography variant="h4">New User</Typography>
                <Spacing multiplier={5} />
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row>
                  <Col md="5">
                    <Field
                      component={Input}
                      name="firstName"
                      required
                      fullwidth
                      label="First Name"
                      validate={(value) => validateInput(value, "text", true)}
                    />
                  </Col>
                  <Col md="5">
                    <Field
                      component={Input}
                      name="lastName"
                      required
                      fullwidth
                      label="Last Name"
                      validate={(value) => validateInput(value, "text", true)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="5">
                    <Field
                      component={Input}
                      name="password"
                      required
                      fullwidth
                      label="Password"
                      type="password"
                      autoComplete
                      validate={(value) =>
                        validateInput(value, "password", true)
                      }
                    />
                  </Col>
                  <Col md="5">
                    <Field
                      component={Input}
                      name="confirmPassword"
                      required
                      fullwidth
                      label="Confirm Password"
                      type="password"
                      autoComplete
                      validate={(value) =>
                        validateInput(value, "password", true)
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="10">
                    <Field
                      component={Input}
                      name="email"
                      required
                      fullwidth
                      label="Email Address"
                      type="email"
                      autoComplete
                      validate={(value) => validateInput(value, "email", true)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Button
                      disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                      type="Submit"
                      loading={createUserByAdminLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Copy Secure Link"
                          size="24px"
                        />
                      }
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
  );
}
