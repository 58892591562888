import React, { useContext } from "react";
import { Form, Field } from "react-final-form";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  Spacing,
  Input,
  Button,
  validateInput,
  Typography,
  validatePhoneNumber,
  DatePicker,
  Select,
  Checkbox,
  getCountryNames,
} from "@web-applications/daffodil-component-library";
import platform from "platform";
import { validate as postcodeValidate } from "postal-codes-js";
import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import { CaptivePortalContext } from "./CaptivePortal";

const REGISTER_WIFI_USER = gql`
  mutation registerWifiUser(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $dateOfBirth: DateTime
    $gender: String
    $postcode: String
    $mobile: String
    $country: String!
    $password: String!
    $siteReference: String!
    $clientMacAddress: MAC!
    $deviceOS: String!
    $loginUrl: String!
    $browser: String!
    $accessPointId: ID!
    $marketingMethods: [String]
  ) {
    registerWifiUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      dateOfBirth: $dateOfBirth
      gender: $gender
      postcode: $postcode
      mobile: $mobile
      country: $country
      password: $password
      siteReference: $siteReference
      clientMacAddress: $clientMacAddress
      deviceOS: $deviceOS
      loginUrl: $loginUrl
      browser: $browser
      accessPointId: $accessPointId
      marketingMethods: $marketingMethods
    ) {
      redirectUrl
      userId
    }
  }
`;

export default function Register() {
  const {
    setRedirectUrl,
    showAgreementPopup,
    siteReference,
    clientMac,
    loginUrl,
    registerAccessPointData,
    setCurrentForm,
    hiddenFields,
  } = useContext(CaptivePortalContext);

  const [registerWifiUser, { loading: registerWifiUserLoading }] = useMutation(
    REGISTER_WIFI_USER,
    {
      onCompleted: ({ registerWifiUser: { redirectUrl, userId } }) => {
        sessionStorage.setItem("userId", userId);

        setRedirectUrl(redirectUrl);
        showAgreementPopup();
      },
    }
  );

  const countriesList = getCountryNames();

  //format countries list for dropdown
  const formattedCountryList = countriesList.map(({ code, name }) => ({
    value: code,
    label: name,
  }));

  return (
    <Form
      initialValues={{ country: "GB" }}
      onSubmit={({
        firstName,
        lastName,
        email,
        dateOfBirth,
        gender,
        country,
        mobile,
        postcode,
        confirmPassword,
        marketingMethods,
      }) => {
        const response = registerWifiUser({
          variables: {
            firstName,
            lastName,
            email,
            dateOfBirth,
            gender,
            postcode,
            mobile,
            country,
            password: confirmPassword,
            siteReference,
            clientMacAddress: clientMac,
            deviceOS: platform.os.family + " " + platform.os.version,
            loginUrl,
            browser: platform.name || "Unknown",
            accessPointId: registerAccessPointData.registerAccessPoint.id,
            marketingMethods,
          },
        }).catch(() => {
          return {
            email: "Email already in use",
          };
        });

        if (!response.data) return response;
      }}
      validate={({
        mobile,
        postcode,
        country,
        confirmPassword,
        password,
        marketingMethods,
      }) => {
        const errors = {};

        if (mobile && country)
          errors.mobile = validatePhoneNumber(mobile, country);

        if (postcode && country && postcodeValidate(country, postcode) !== true)
          errors.postcode = "Invalid Postcode";

        if (confirmPassword && confirmPassword !== password) {
          errors.confirmPassword = "Passwords Must Match";
        }
        if (
          marketingMethods?.includes("Call") ||
          marketingMethods?.includes("Text")
        ) {
          errors.mobile = "Required";
        }

        return errors;
      }}
      render={({
        values,
        handleSubmit,
        pristine,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Container>
            <Row justifyContent="center">
              <Col md="8" lg="5">
                <Button
                  fullwidth
                  type="button"
                  onClick={() => {
                    setCurrentForm("login");
                  }}
                >
                  Login
                </Button>
                <Spacing multiplier={3} />
                <Typography variant="bodySmall" center>
                  or
                </Typography>
                <Spacing multiplier={3} />
                <Typography variant="h2" center>
                  Register
                </Typography>
                <Spacing multiplier={3} />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="5">
                <Field
                  component={Input}
                  name="firstName"
                  required
                  fullwidth
                  label="First Name"
                  validate={(value) => validateInput(value, "text", true)}
                />
              </Col>
              <Col md="5">
                <Field
                  component={Input}
                  name="lastName"
                  required
                  fullwidth
                  label="Last Name"
                  validate={(value) => validateInput(value, "text", true)}
                />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="10" lg="5">
                <Field
                  component={Input}
                  name="email"
                  required
                  fullwidth
                  label="Email Address"
                  type="email"
                  autoComplete
                  validate={(value) => validateInput(value, "email", true)}
                />
              </Col>

              {!hiddenFields.includes("Date of birth") && (
                <Col md="5" lg="3">
                  <Field
                    component={DatePicker}
                    name="dateOfBirth"
                    fullwidth
                    label="Date Of Birth"
                    showYearDropdown
                    maxDate={new Date()}
                  />
                </Col>
              )}

              {!hiddenFields.includes("Gender") && (
                <Col md="5" lg="2">
                  <Field
                    component={Select}
                    name="gender"
                    label="Gender"
                    fullwidth
                    options={[
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                      { value: "Other", label: "Other" },
                      { value: "Rather not say", label: "Rather not say" },
                    ]}
                    format={(value) => {
                      const selectedOption = [
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                        { value: "Other", label: "Other" },
                        { value: "Rather not say", label: "Rather not say" },
                      ].find((option) => option.value === value);

                      return selectedOption?.label;
                    }}
                  />
                </Col>
              )}
            </Row>
            <Row justifyContent="center">
              {!hiddenFields.includes("Country") && (
                <Col md="10" lg="5">
                  <Field
                    component={Select}
                    name="country"
                    label="Country"
                    options={formattedCountryList}
                    fullwidth
                    required
                    // validate={(value) =>
                    //   validateInput(value, "dropdownLookup", true)
                    // }
                  />
                </Col>
              )}

              {!hiddenFields.includes("Mobile") && (
                <Col md="5" lg="3">
                  <Field
                    component={Input}
                    name="mobile"
                    type="number"
                    label="Mobile"
                    fullwidth
                  />
                </Col>
              )}

              {!hiddenFields.includes("Postcode") && (
                <Col md="5" lg="2">
                  <Field
                    component={Input}
                    type="postcode"
                    name="postcode"
                    label="Postcode"
                    fullwidth
                  />
                </Col>
              )}
            </Row>
            <Row justifyContent="center">
              <Col md="5">
                <Field
                  component={Input}
                  name="password"
                  required
                  fullwidth
                  label="Password"
                  type="password"
                  autoComplete
                  validate={(value) => validateInput(value, "password", true)}
                />
              </Col>
              <Col md="5">
                <Field
                  component={Input}
                  name="confirmPassword"
                  required
                  fullwidth
                  label="Confirm Password"
                  type="password"
                  autoComplete
                  validate={(value) => validateInput(value, "password", true)}
                />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md="10">
                <Typography variant="bodySmall">
                  In order to use this service, you must agree to our{" "}
                  <Typography
                    variant="bodySmall"
                    component="a"
                    href="http://daffodil-wifi.co.uk/terms-and-conditions/"
                    colour="#1344B4"
                    inline
                  >
                    Terms and Conditions
                  </Typography>
                </Typography>
                <Spacing multiplier={2} />
                <Field
                  component={Checkbox}
                  name="wifiTerms"
                  type="checkbox"
                  label="I agree to Daffodil Wifi Terms and Conditions"
                  validate={(value) => validateInput(value, "checkbox", true)}
                />
              </Col>
            </Row>

            <Row justifyContent="center">
              <Col md="10">
                <Typography variant="bodySmall">
                  I would like to recieve marketing for Daffodil Wifi using the
                  following:
                </Typography>
                <Spacing multiplier={1} />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Call"
                />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Text"
                />
                <Field
                  component={Checkbox}
                  name="marketingMethods"
                  type="checkbox"
                  value="Email"
                />
              </Col>
            </Row>

            <Row justifyContent="center">
              <Col md="4">
                <Spacing multiplier={4} />
                <Button
                  disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                  type="Submit"
                  loading={registerWifiUserLoading}
                  fullwidth
                  icon={
                    <Icon
                      path={mdiContentSave}
                      title="Copy Secure Link"
                      size="24px"
                    />
                  }
                >
                  Register
                </Button>
              </Col>
            </Row>
          </Container>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
  );
}
