export default {
  button: {
    buttonBackground: '#FDC800',
    buttonDisabledBackground: '#1C1C26',
    buttonOutlineBorder: '#C3CAD8',
    buttonText: '#1C1C26',
  },
  input: {
    inputBackground: '#FFFFFF',
    inputBorder: '#C3CAD8',
    inputDisabledBackground: '#1C1C26',
    inputText: '#1C1C26',
    inputDisabledText: '#626C79',
    selectDropdownIcon: '#1c1c26',
  },
  typography: {
    primary: 'Poppins, Helvetica, Arial, Sans-Serif',
    secondary: 'Roboto, Helvetica, Arial, Sans-Serif',
    labelColour: '#626C79',
    linkColour: '#3F3CC6',
    linkHoverColour: '#1344B4',
    defaultColour: '#1C1C26',
  },

  colours: {
    success: '#66BC0C',
    destructive: '#E02020',
    destructiveText: '#FFFFFF',
    error: '#E02020',
    icon: '#C3CAD8',
    brand: '#FDC800',
    helperText: '#626C79',

    mast: '#2D248A',
    pitch: '#1C1C26',
    slate: '#626C79',
    mirror: '#C3CAD8',
    steel: '#DFE3EB',
    chalk: '#F3F8FC',
    snow: '#FFFFFF',
    lime: '#66BC0C',
    amber: '#F6992B',
    ruby: '#E02020',
    azure: '#1344B4',
  },
  baseline: 8,
  // grid
  container: {
    padding: 40, // default 15
    maxWidth: {
      xl: 1220, // default 1140
      lg: 980, // default 960
      md: 740, //defaul 720
      sm: 560, //default 540
      xs: 560, //default 540
    },
  },
}
