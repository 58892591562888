import React from "react";
import { Switch } from "react-router-dom";

import AuthenticatedRoute from "../util/AuthenticatedRoute";
import UnsecureRoute from "../util/UnsecureRoute";

// Pages
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgottenPassword from "../pages/ForgottenPassword";
import ResetPassword from "../pages/ResetPassword";
import PageNotFound from "../pages/PageNotFound";
import Site from "../pages/Site/Site";
import UserDetails from "./../pages/User/UserDetails";
import NewSite from "../pages/Site/NewSite";
import EditSite from "../pages/Site/EditSite";
import EditCaptivePortal from "../pages/Site/EditCaptivePortal";
import CaptivePortal from "../pages/CaptivePortal/CaptivePortal";
import NewBusiness from "../pages/Business/NewBusiness";
import BusinessDetails from "../pages/Business/BusinessDetails";
import UserList from "../pages/AdminSettings/Users/UserList";
import UserView from "../pages/AdminSettings/Users/UserView";
import NewUser from "../pages/AdminSettings/Users/NewUser";
import AdminSettings from "../pages/AdminSettings/AdminSettings";

const Routes = (
  <Switch>
    <UnsecureRoute path="/login" component={Login} title="Login" />
    <UnsecureRoute
      path="/forgotten-password"
      exact
      component={ForgottenPassword}
      title="Forgotten Password"
    />
    <UnsecureRoute
      path="/reset-password/:resetToken"
      exact
      component={ResetPassword}
      title="Reset Password"
    />
    <AuthenticatedRoute path="/" exact component={Home} title="Home" />
    {/* User*/}
    <AuthenticatedRoute path="/edit-user" exact component={UserDetails} />
    {/* Site */}
    <AuthenticatedRoute path="/site/:siteReference" exact component={Site} />
    <AuthenticatedRoute
      path="/business/:businessId/site/add"
      exact
      component={NewSite}
      title="Add New Site"
    />
    <AuthenticatedRoute
      path="/edit-site/:siteReference"
      exact
      component={EditSite}
    />
    <AuthenticatedRoute
      path="/edit-captive-portal/:siteReference"
      exact
      component={EditCaptivePortal}
    />
    {/* Business */}
    <AuthenticatedRoute
      path="/businesses/add"
      exact
      component={NewBusiness}
      title="Add New Business"
    />
    <AuthenticatedRoute
      path="/business/:businessId"
      exact
      component={BusinessDetails}
    />
    {/* Admin Settings */}
    <AuthenticatedRoute
      path="/admin-settings/"
      exact
      component={AdminSettings}
      title="User"
    />
    <AuthenticatedRoute
      path="/admin-settings/users"
      exact
      component={UserList}
      title="User"
    />
    <AuthenticatedRoute
      path="/admin-settings/user/edit/:userId"
      exact
      component={UserView}
      title="Edit User"
    />
    <AuthenticatedRoute
      path="/admin-settings/users/add"
      exact
      component={NewUser}
      title="Add New User"
    />
    {/* Captive Portal */}
    <UnsecureRoute
      path="/cp"
      component={CaptivePortal}
      title="Captive Portal"
    />
    {/* 404 */}
    <AuthenticatedRoute component={PageNotFound} title="404" />
  </Switch>
);

export default Routes;
